import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    initialEventState,
    selectEventState,
    setEventData,
    setEventProperty,
} from "../../slices/eventsSlice";
import {routes} from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import SelectInput from "../../components/form/SelectInput";
import {
    useCreateCustomerMutation,
    useCreateEventMutation,
    useFindHotelsQuery, useFindCustomersQuery,
    useFindEventByIdQuery, useFindIndustriesQuery, useFindSegmentsQuery,
    useUpdateEventMutation, useFindRoomsQuery,
} from "../../api/eventsStoreApi";
import DateInput from "../../components/form/DateInput";
import TextAreaInput from "../../components/form/TextAreaInput";
import {PrimeIcons} from "primereact/api";
import {Dialog} from "primereact/dialog";
import {selectCustomerState, setCustomerProperty} from "../../slices/settingsSlice";
import {toast} from "react-toastify";
import {ProgressSpinner} from "primereact/progressspinner";

const UpdateEvent: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading: isPrepareLoading} = useFindEventByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateEvent,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useUpdateEventMutation();
    const [
        createEvent,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreateEventMutation();

    const {event} = useAppSelector(selectEventState);
    const {
        name,
        description,
        customerId,
        hotelId,
        roomId,
        startDate,
        endDate,
        segmentId,
        industryId
    } = event;

    const {data: customersPage} = useFindCustomersQuery({page: 0, size: 10000, sort: ["name"]});
    const {data: hotelsPage} = useFindHotelsQuery({page: 0, size: 10000, sort: ["name"]});
    const {data: roomsPage} = useFindRoomsQuery({page: 0, size: 10000, sort: ["name"]});
    const {data: segmentsPage} = useFindSegmentsQuery({page: 0, size: 10000, sort: ["name"]});
    const {data: industriesPage} = useFindIndustriesQuery({page: 0, size: 10000, sort: ["name"]});

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    useEffect(() => {
        dispatch(setEventData({property: "event", value: initialEventState}));

        if (isSuccess) {
            navigate(`/${routes.EVENTS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateEvent({id: id, eventDto: event})
            : createEvent({eventDto: event});
    };

    const {name: customerName} = useAppSelector(selectCustomerState);

    const [addProduct, {
        isLoading: isCreateCustomerLoading,
        isSuccess: isCreateCustomerSuccess,
        isError: isCreateCustomerError
    }] = useCreateCustomerMutation();

    const [visible, setVisible] = React.useState(false);

    const clearNameValue = () => {
        dispatch(setCustomerProperty({property: "name", value: ""}));
    };

    useEffect(() => {
        if (isCreateCustomerSuccess || isCreateCustomerError) {
            clearNameValue();
            if (isCreateCustomerSuccess) {
                setVisible(false);
            } else if (isCreateCustomerError) {
                toast.error("Occurs some problem with adding new customer, try again later");
            }
        }
    }, [isCreateCustomerSuccess, isCreateCustomerError]);

    return (
        <>
            <Dialog
                visible={visible}
                onHide={() => {
                    setVisible(false);
                    clearNameValue();
                }}
                header="Add customer"
                style={{width: "30vw"}}
                footer={
                    <Button
                        icon={PrimeIcons.PLUS}
                        onClick={() => addProduct({customerDto: {name: customerName}})}
                        disabled={isCreateCustomerLoading}
                        label="Add"
                    />
                }
                contentStyle={{padding: "1.5rem"}}
            >
                {isCreateCustomerLoading ?
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><ProgressSpinner/>
                    </div> : <TextInput
                        id="name"
                        name="name"
                        label="Customer name"
                        value={customerName}
                        setProperty={setCustomerProperty}
                        style={{width: "100%"}}
                    />}

            </Dialog>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id
                        ? `Add event`
                        : `Update event ${name}`}
                </h3>
                <TextInput
                    id="name"
                    name="name"
                    value={name}
                    label="Name"
                    setProperty={setEventProperty}
                />
                <TextAreaInput
                    id="description"
                    name="description"
                    value={description}
                    label="Description"
                    setProperty={setEventProperty}
                />
                <SelectInput
                    id="customerId"
                    options={customersPage?.content?.map(item => {
                        return {value: item.id, label: item.name}
                    }) || []}
                    name="customerId"
                    value={customerId}
                    label="Customer"
                    setProperty={setEventProperty}
                    button={<Button label="New" icon={PrimeIcons.PLUS} onClick={() => setVisible(true)}/>}
                    filter
                />
                <SelectInput
                    id="hotelId"
                    options={hotelsPage?.content?.map(item => {
                        return {value: item.id, label: item.name}
                    }) || []}
                    name="hotelId"
                    value={hotelId}
                    label="Hotel"
                    setProperty={setEventProperty}
                    filter
                />
                <SelectInput
                    id="roomId"
                    options={roomsPage?.content
                        ?.filter(room => room.hotelId === hotelId)
                        ?.map(item => {
                        return {value: item.id, label: item.name}
                    }) || []}
                    name="roomId"
                    value={roomId}
                    label="Room"
                    setProperty={setEventProperty}
                    filter
                />
                <DateInput
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    label="Start date"
                    dateFormat="yy-mm-dd"
                    setProperty={setEventProperty}
                />
                <DateInput
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    label="End date"
                    dateFormat="yy-mm-dd"
                    setProperty={setEventProperty}
                />
                <SelectInput
                    id="segmentId"
                    options={segmentsPage?.content?.map(item => {
                        return {value: item.id, label: item.name}
                    }) || []}
                    name="segmentId"
                    value={segmentId}
                    label="Segment"
                    setProperty={setEventProperty}
                    filter
                />
                <SelectInput
                    id="industryId"
                    options={industriesPage?.content?.map(item => {
                        return {value: item.id, label: item.name}
                    }) || []}
                    name="industryId"
                    value={industryId}
                    label="Industry"
                    setProperty={setEventProperty}
                    filter
                />
                <Button label="Save" className="mt-5 w-full" onClick={onSubmit}/>
            </div>
        </>
    );
};

export default UpdateEvent;
