import React, {FC} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import eventActionTemplate from "./Actions";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectEventState, setEventData
} from "../../slices/eventsSlice";
import {routes} from "../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {
    EventDto,
    useFindHotelsByLoggedUserQuery, useFindCitiesQuery,
    useFindCustomersQuery,
    useFindEventsQuery, useFindIndustriesQuery, useFindSegmentsQuery, useFindRoomsQuery
} from "../../api/eventsStoreApi";
import MultiSelectInput from "../../components/form/MultiSelectInput";
import TextInput from "../../components/form/TextInput";
import DateRangeInput from "../../components/form/DateRangeInput";
import {PrimeIcons} from "primereact/api";
import {checkIfLoggedUserHaveAccess, userLoggedRole} from "../../utils/loggedUserUtils";
import CheckboxInput from "../../components/form/CheckboxInput";
import {format, parse} from "date-fns";
import {INSTANT_FORMAT} from "../../utils/dateUtils";
import {exportToExcel} from "./eventsExporter";

const Events: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {
        eventFilter,
        customerIds,
        hotelIds,
        segmentIds,
        industryIds,
        startDateFrom,
        endDateTo,
        deleted
    } = useAppSelector(selectEventState);
    const {isLoading, data} = useFindEventsQuery({
        page: 0,
        size: 10000,
        sort: ['startDate,desc'],
        deleted: deleted,
        searchParamsEvent: {
            customerIds: customerIds,
            hotelIds: hotelIds,
            segmentIds: segmentIds,
            industryIds: industryIds,
            startDateFrom: startDateFrom,
            endDateTo: endDateTo
        }
    });

    const {data: customersPage, isLoading: isCustomersLoading} = useFindCustomersQuery({page: 0, size: 10000, sort: ["name"]});
    const customers = customersPage?.content ? Object.fromEntries(customersPage?.content?.map(e => [e.id, e.name])) : [];

    const {data: hotelsPage, isLoading: isHotelsLoading} = useFindHotelsByLoggedUserQuery({
        page: 0,
        size: 10000,
        sort: ["name"]
    });
    const hotels = hotelsPage?.content ? Object.fromEntries(hotelsPage?.content?.map(e => [e.id, e])) : [];

    const {data: roomsPage, isLoading: isRoomsLoading} = useFindRoomsQuery({
        page: 0,
        size: 10000,
        sort: ["name"]
    });
    const rooms = roomsPage?.content ? Object.fromEntries(roomsPage?.content?.map(e => [e.id, e])) : [];

    const {data: citiesPage, isLoading: isCitiesLoading} = useFindCitiesQuery({page: 0, size: 10000, sort: ["name"]});
    const cities = citiesPage?.content ? Object.fromEntries(citiesPage?.content?.map(e => [e.id, e.name])) : [];

    const {data: segmentsPage, isLoading: isSegmentsLoading} = useFindSegmentsQuery({page: 0, size: 10000, sort: ["name"]});
    const segments = segmentsPage?.content ? Object.fromEntries(segmentsPage?.content?.map(e => [e.id, e.name])) : [];

    const {data: industriesPage, isLoading: isIndustriesLoading} = useFindIndustriesQuery({page: 0, size: 10000, sort: ["name"]});
    const industries = industriesPage?.content ? Object.fromEntries(industriesPage?.content?.map(e => [e.id, e.name])) : [];

    const isDataLoading = isCustomersLoading || isHotelsLoading || isRoomsLoading || isCitiesLoading || isSegmentsLoading || isIndustriesLoading || isLoading;

    const loggedRole = userLoggedRole();

    const columns = [
        {field: "name", header: "Name", sortable: true, exportable: true},
        {
            field: "customerId",
            header: "Customer",
            body: (row: EventDto) => row.customerId && customers[row.customerId],
            sortable: true,
            exportable: true
        },
        {
            field: "hotelId",
            header: "Hotel",
            body: (row: EventDto) => row.hotelId && hotels[row.hotelId] && hotels[row.hotelId].name,
            sortable: true,
            exportable: true
        },
        {
            field: "roomId",
            header: "Room",
            body: (row: EventDto) => row.roomId && rooms[row.roomId] && rooms[row.roomId].name,
            sortable: true,
            exportable: true
        },
        {
            field: "maxCapacity",
            header: "Max capacity",
            body: (row: EventDto) => row.roomId && rooms[row.roomId] && rooms[row.roomId].maxCapacity,
            sortable: true,
            exportable: true
        },
        {
            field: "cityId",
            header: "City",
            body: (row: EventDto) => row.hotelId && hotels[row.hotelId] && cities[hotels[row.hotelId].cityId],
            sortable: true,
            exportable: true
        },
        {field: "startDate", header: "Start date", sortable: true, exportable: true},
        {field: "endDate", header: "End date", sortable: true, exportable: true},
        {field: "dayCount", header: "Days", sortable: true, exportable: true},
        {
            field: "segmentId",
            header: "Segment",
            body: (row: EventDto) => row.segmentId && segments[row.segmentId],
            sortable: true,
            exportable: true
        },
        {
            field: "industryId",
            header: "Industry",
            body: (row: EventDto) => row.industryId && industries[row.industryId],
            sortable: true,
            exportable: true
        },
        {
            field: "deleted",
            header: "Deleted",
            body: (row: EventDto) => row.deleted ? "Yes" : "No",
            hidden: true,
            sortable: true,
            exportable: true
        },
        {
            field: "createdBy",
            header: "Created by",
            hidden: userLoggedRole() !== "ADMIN",
            sortable: true,
            exportable: checkIfLoggedUserHaveAccess(loggedRole, "MODERATOR"),
        },
        {
            field: "createdDate",
            header: "Created date",
            hidden: userLoggedRole() !== "ADMIN",
            body: (row: EventDto) => (!!row.createdDate ? format(parse(row.createdDate, INSTANT_FORMAT, new Date()), "yyyy-MM-dd hh:mm:ss") : ""),
            sortable: true,
            exportable: false
        },
        {
            field: "",
            header: "",
            hidden: !checkIfLoggedUserHaveAccess(loggedRole, "MODERATOR"),
            body: eventActionTemplate,
            exportable: false
        },
    ];

    const exportExcel = () => {
        exportToExcel(data?.content || [], columns);
    };

    const header = (
        <>
            <div className="ml-5 m-5 flex flex-row w-full align-content-end gap-5">
                <div className="w-full">
                    <MultiSelectInput
                        id="customerIds"
                        options={customersPage?.content?.map(item => {
                            return {value: item.id, label: item.name}
                        }) || []}
                        name="customerIds"
                        value={customerIds}
                        label="Customer"
                        setProperty={setEventData}
                    />

                    <MultiSelectInput
                        id="hotelIds"
                        options={hotelsPage?.content?.map(item => {
                            return {value: item.id, label: item.name}
                        }) || []}
                        name="hotelIds"
                        value={hotelIds}
                        label="Hotel"
                        setProperty={setEventData}
                    />
                    <MultiSelectInput
                        id="segmentIds"
                        options={segmentsPage?.content?.map(item => {
                            return {value: item.id, label: item.name}
                        }) || []}
                        name="segmentIds"
                        value={segmentIds}
                        label="Segment"
                        setProperty={setEventData}
                    />
                    <MultiSelectInput
                        id="industryIds"
                        options={industriesPage?.content?.map(item => {
                            return {value: item.id, label: item.name}
                        }) || []}
                        name="industryIds"
                        value={industryIds}
                        label="Industry"
                        setProperty={setEventData}
                    />
                    <div className="flex gap-5">
                        <div className="w-30rem">
                            <DateRangeInput
                                id="dates"
                                nameFrom="startDateFrom"
                                nameTo="endDateTo"
                                valueFrom={startDateFrom}
                                valueTo={endDateTo}
                                label="Start dates range"
                                dateFormat="yyyy-MM-dd"
                                setProperty={setEventData}/>
                        </div>
                        <div className="w-30rem mt-2">
                            <CheckboxInput
                                id="deleted"
                                name="deleted"
                                value={deleted}
                                label="Show deleted"
                                setProperty={setEventData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {checkIfLoggedUserHaveAccess(loggedRole, "MODERATOR") &&
                <Button onClick={() => navigate(routes.ADD)} label="Add event" icon={PrimeIcons.PLUS}/>}
        </>
    );

    const footer = (
        <div className="flex justify-content-end gap-3">
            <Button label="Export to Excel" type="button" icon="pi pi-file-excel" rounded
                    onClick={exportExcel} data-pr-tooltip="XLS"/>
        </div>
    );

    const deletedRowClass = (data: EventDto) => {
        return {
            'text-white-alpha-20': data.deleted
        };
    };

    return (
        <>
            <TextInput
                id="eventFilter"
                name="eventFilter"
                label=""
                value={eventFilter}
                setProperty={setEventData}
                searchIcon
                placeholder="Search"
            />
            <TableWrapper
                header="Events"
                isLoading={isDataLoading}
                data={data?.content}
                columns={columns}
                addButton={header}
                globalFilter={eventFilter}
                rowClassName={deletedRowClass}
                stateKey="events-list"
                footer={footer}
            />
        </>
    );
};

export default Events;
